<template>
	<w-flex scroll-y>
		<w-layout column fill-height>
			<w-flex v-if="loadings > 0" align-center d-flex fill-height justify-center text-xs-center>
				<w-flex shrink>
					<v-progress-circular color="primary" indeterminate />
				</w-flex>
			</w-flex>
			<w-flex v-if="loadings === 0" scroll-y x>
				<w-flex shrink @click.stop>
					<v-treeview :items="projects" hoverable item-children="services">
						<template v-slot:label="{ item }">
							<ProjectItem v-if="isProject(item)" :is-selected="selectedProjectId === item.id" :value="item" />
							<ServiceItem v-else :is-selected="selectedServiceId === item.id" :value="item" />
						</template>
					</v-treeview>
				</w-flex>
			</w-flex>
			<w-flex shrink text-xs-center @click.stop>
				<ProjectsPaginationSelector />
			</w-flex>
		</w-layout>
	</w-flex>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'
import ProjectsListMixin from '@/mixins/Offers/Projects/ProjectsList'
import Project from '@/classes/Offers/Project'

export default {
	name: 'ProjectsListTreeview',
	components: {
		ProjectItem: () => ({
			component: import('@/components/Offers/CustomersManager/Lists/Treeview/ProjectItem')
		}),
		ProjectsPaginationSelector: () => ({
			component: import('@/components/Offers/CustomersManager/ProjectsPaginationSelector')
		}),
		ServiceItem: () => ({
			component: import('@/components/Offers/CustomersManager/Lists/Treeview/ServiceItem')
		})
	},
	mixins: [ProjectsManagerModuleGuard, ProjectsListMixin],
	data: function () {
		return {
			cancelToken: null,
			loadings: 0,
			projects: []
		}
	},
	methods: {
		filtersWatcher: function () {
			this.listProjects()
		},
		isProject: function (element) {
			return Project.prototype.isPrototypeOf(element)
		},
		listProjects: function () {
			this.loadings++
			this.projects = []

			const callAnswer = this.service.listProjects(this.accountingFirmId, this.vendorId, this.filters, this.pagination, this.cancelToken)
			this.cancelToken = callAnswer.cancelToken

			return callAnswer.promise
				.then(({ paginationData, projects }) => {
					this.$store.dispatch('customers/projects/setPagination', paginationData)
					this.projects = projects
				})
				.finally(() => {
					this.loadings--
				})
		},
		onProjectCreated: function () {
			this.listProjects()
		},
		onProjectCompleted: function () {
			this.listProjects()
		},
		onProjectDeleted: function () {
			this.listProjects()
		},
		onProjectUpdated: function (project) {
			this.updateProjectObject(project)
		},
		onServiceCreated: function (service) {
			const project = this.projects.find(p => p.id === service.project_id)
			if (project) {
				project.services.push(service)
			}
		},
		onServiceDeleted: function (service) {
			const project = this.projects.find(p => p.id === service.project_id)
			if (!project) {
				return
			}
			const serviceIndex = project.services.findIndex(s => s.id === service.id)

			if (serviceIndex !== -1) {
				project.services.splice(serviceIndex, 1)
			}
		},
		onServiceUpdated: function (service) {
			const project = this.projects.find(p => p.id === service.project_id)
			if (!project) {
				return
			}
			const serviceItem = project.services.find(s => s.id === service.id)

			if (serviceItem) {
				serviceItem.update(service)
			}
		},
		paginationWatcher: function () {
			this.listProjects()
		},
		setPagination: function (paginationData) {
			this.$store.dispatch('customers/projects/setPagination', paginationData)
		},
		updateProjectObject: function (newObject) {
			const project = this.projects.find(p => p.id === newObject.id)
			if (project) {
				project.update(newObject)
			}
		}
	}
}
</script>

<style scoped>
:deep(.v-treeview-node__root) {
	min-height: 56px;
}
</style>
